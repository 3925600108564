import React from 'react'
import ReactDOM from 'react-dom'

import './Styles/global.scss'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { Header, Footer, Container} from './Views/Layout'
import { AuthProvider } from './Contexts/auth'
import { Routes, CustomRoute } from './Routes'


ReactDOM.render(
  <Router>
    <AuthProvider>
      <Header  />

      <div className="page-content">
        <Switch>
          {Routes.map((routeProps, index) => (
            <CustomRoute key={index} {...routeProps} />
          ))}
        </Switch>
      </div>

      <Footer />
    </AuthProvider>
  </Router>,
  document.querySelector('#root'),
)
