import React from 'react';
import api from './api';
import {getLocalToken } from './ictsAuth';

const getHeader = () => {
  const token = getLocalToken();
 
  const headers = {
    headers: {
      auth: token
    }
  };

  return headers
}

export const GetUsersData = async () => {

  
  let result = {};
  const headers = getHeader();


  await api.get('/me', headers)
    .then(response => {
      if (response.data != null) {
        result = response.data;

      }
    }
    ).catch(error => {
      console.log(error.response.statusText)
      result = {
        status: "NOK"
      }

    })
  return result;

}

export const GetCredentials = async () => {
  let credentials = {};
  const headers = getHeader();

  await api.get('/credentials', headers)
    .then(response => {
      if (response.data != null) {
        credentials = response.data
      }
    }
    ).catch(erro => {
      console.log(erro.response.statusText)
      credentials = {
        status: "NOK"

      }
    });


  return credentials;
}

export const registerLastLogon = async (userId) => {
  const body = {
    userId: userId
  };
  let resultado = {};
  const headers = getHeader();

  try {
    const response = await api.post('/lastlogin', body, headers)
      .then(res => res.data)
      .then(data => resultado = data)

  } catch (error) {

    resultado = {
      status: "NOK"
    }
  }
}

export const getAllReportsFromUser = async (userId) => {
  const body = {
    userId: userId
  };

  let resultado = {};

  try {
    const response = await api.post('/reports', body)
    if (response.data) {
      if (response.data.dados) {
        let dados = response.data.dados;
        dados.map((item, i) => {
          resultado[i] = {
            workspaceId: item.workspace,
            reports: item.relatorios,
            status: 'OK'
          }
        });

      }
    }


  } catch (error) {
    resultado = {
      status: "NOK"
    }

  }
  return resultado;

}

export const getClientsForUser = async (userId) => {
  const body = {
    userId: userId
  };
  let resultado = {};


  const response = await api.post('/clients', body);
  if (response) {
    resultado = response.data;


  }


  return resultado;
}

export const getClientsReports = async (clientId) => {
  const body = {
    clientId: clientId
  };
  let resultado = {};
  const headers = getHeader();
  try {
    const response = await api.post('/clientsreports', body, headers);
    if (response.data && response.data[0].sucess) {
      resultado = {
        workspaceId: response.data[0].workspaceId,
        reports: response.data[0].reports,
        status: "OK"
      }
    } else {
      resultado = {
        status: "NOK"
      }
    }
  } catch (error) {
    console.log(error.response);
    resultado = {
      status: "NOK"
    }
  }

  return resultado;
}

export const registerClickReport = async (userId, reportId) => {
  const body = {
    userId: userId,
    reportId: reportId
  };
  let resultado = {};
  const headers = getHeader();

  try {
    const response = await api.post('/click', body, headers)
      .then(res => res.data)
      .then(data => resultado = data)

  } catch (error) {

    resultado = {
      status: "NOK"
    }
  }
}

export const getUserReports = async (userId, clientId) =>{
  let resultado = {}
  const body = {
    userId: userId,
    clientId: clientId
  };
  const headers = getHeader();
  try {
    const response = await api.post('/relatorios', body, headers);
    if (response.data) {
     resultado ={
       sucess: true,
       dados: response.data
     } 
     
    } else {
      resultado = {
        status: "NOK"
      }

    }
  } catch (error) {
    console.log(error.statusText);
    resultado = {
      status: "NOK"
    }
  }
  return resultado;


}
