import './Alert.scss';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../icts-icon';
import Button from '../../icts-button';

const Alert = ({
  iconAlert,
  iconError,
  title,
  description,
  textButtonPrimary,
  textButtonSecondary,
}) => (
  <div className="modal__alert">
    { iconAlert && (
      <Icon name="alerta" size="lg" />
    )}
    { iconError && (
      <Icon name="erro" size="lg" />
    )}
    <h4 className="modal__alert--title">{title}</h4>
    <p className="modal__alert--description body__default">{description}</p>
    <div className="alert-buttons">
      <Button
        size="sm"
        type="secondary"
        text={textButtonSecondary}
      />
      <Button
        size="sm"
        type="primary"
        text={textButtonPrimary}
      />
    </div>
  </div>
);

Alert.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  textButtonPrimary: PropTypes.string,
  textButtonSecondary: PropTypes.string,
  iconAlert: PropTypes.bool,
  iconError: PropTypes.bool,
};

Alert.defaultProps = {
  textButtonPrimary: '',
  textButtonSecondary: '',
  iconAlert: false,
  iconError: false,
};

export default Alert;
