import axios from "axios";
import React from 'react';



const api = axios.create({
  baseURL:process.env.REACT_APP_URL_BACKEND,
  headers:{
    Authorization: process.env.REACT_APP_AUTHORIZATION
  }
});

export default api;