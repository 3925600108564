import React, { useState, useEffect, useContext } from 'react'
import { Row, Col } from 'icts-layout'
import { useHistory } from 'react-router-dom'
import { GetUsersData} from '../../../Requests/Requests'
import { SelectInput } from 'icts-form';
import Button from 'icts-button';

import styles from './Home.module.scss';
import Alert from 'icts-alert';
import Modal from 'icts-modal';
import useEventListener from '../../Components/useEvent';
import { AuthContext } from '../../../Contexts/auth';



export const HomeRelatorios = () => {
  const { logout } = useContext(AuthContext);
  const [listaClientes, setListaClientes] = useState(false);
  const [cliente, onCliente] = useState([]);

  const[user, setUser] = useState(false);
  const[loadingUser, setLoadingUser] = useState(false);
  const[error, setError] = useState(false);
  const[show, setShow] = useState(false);

  const history = useHistory();


  //pega os elementos botoes do alerta e atribui as funcoes
  const element = document.getElementsByClassName('alert-buttons');
  let cancelButton = '';
  let loginButton = '';
  if(element[0] !== undefined){
    if(element[0].children[1].className === 'button button__primary button--sm'){
      loginButton = element[0].children[1];
    }
    if(element[0].children[0].className === 'button button__secondary button--sm'){
      cancelButton = element[0].children[0];
    }
    

  }
  useEventListener("click", () => toggleModal(), cancelButton);
  useEventListener("click", () => handleLogin(), loginButton);



  useEffect(()=>{
    async function fetchData(){
      if(!user && !loadingUser){
        getUserData();
      }
    }
    fetchData();
  },[user, listaClientes]); 

  //get info user e cliente /me
  async function getUserData(){
    setLoadingUser(true);
    let clients = [];

    let userData = await GetUsersData();
    
    if(userData.success){
      setUser({
        id: userData.id,
        name: userData.name,
        email: userData.email,
        clientes: userData.listClientsUser
      });

      if(userData.listClientsUser != null){
        userData.listClientsUser.map(function (item, i){
          clients[i] = {
            value: item.id,
            label: item.name
          }
        });
      }
     

      

      setListaClientes({
        clientes: clients
      });
    }
    else{
      setShow(!show);
      setError(true);
    }

   

    setLoadingUser(false);

  }

 
  function handleFilter(id) {
    let path = '/client?id=' + `${id}`
    history.push({
      pathname: '/client',
      state:{user: user},
      search: '?id=' + `${id}`
    });

  }

  function toggleModal(){
    setShow(!show)
  }

  function handleLogin() {
        logout();
        setShow(!show)
  }


  return (

    <React.Fragment>
      <div className='div-header-dashboard'>
        <Row xl="16" lg="12" md="8" sm="4" xs="2">
          <Col xl="3">
            <SelectInput              
              searchable
              scrollable
              placeholder="Selecione um cliente"
              items={listaClientes.clientes}
              onSelect={onCliente}
              value={cliente}
              disabled={loadingUser || error}
            />
          </Col>
          <Col xl="1">
            <div className={styles['div-botton']}>
              <Button
                type="primary"
                icon="filtrar"
                size="lg"
                onClick={() => handleFilter(cliente.value)}
                disabled={loadingUser || error}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal 
       show={show}
       handleClose={toggleModal}
       title="Erro"
       size="sm"
       >
        <Alert
        iconError
        title="Erro na atualização"
        description="Parece que você não tem permissão para essa operação. Por favor tente logar novamente ou procure a área de desenvolvimento."
        textButtonPrimary="Logar"
        textButtonSecondary="Cancelar" />
      </Modal>

    </React.Fragment>







  )


}


