import React, { useContext, useEffect } from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { AuthContext } from '../Contexts/auth'

export const CustomRoute = (props) => {
  const { redirectToLogin } = useContext(AuthContext)
  const { protected: isProtected, ...routeProps } = props

  useEffect(() =>{
    ReactGA.initialize('UA-178874612-2')
     ReactGA.pageview(window.location.pathname + window.location.search)
 
   }, [])

  if (isProtected === true) {
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_KEY)

    if (!token) {
      redirectToLogin()
      return null
    }
  }

  return <Route {...routeProps} />
}

CustomRoute.propTypes = {
  protected: PropTypes.bool,
}
