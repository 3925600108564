import React, { useState, useEffect } from 'react'
import { useLocation, Redirect } from 'react-router-dom'

import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'

import Button from 'icts-button';
import { withRouter } from 'react-router';
import api from '../../../Requests/api';
import { registerClickReport } from '../../../Requests/Requests';
import {getLocalToken } from '../../../Requests/ictsAuth';
import Card from 'icts-card';
import BlankState from 'icts-blank-state';
import { Row } from 'icts-layout';

const DashboardViewerPage = () => {
  const location = useLocation()
  const [embeddedComponent, setEmbeddedComponent] = useState(null)
  const [embedConfig, setEmbedConfig] = useState({
    type: 'report',
    id: '',
    embedUrl: '',
    accessToken: '',
    tokenType: models.TokenType.Embed,
    settings: {
      navContentPaneEnabled: true,
      filterPaneEnabled: false,
    },
    filters: ''
  });
  const [error, setError] = useState(false);


  //credentials
  const [credentials, setCredentials] = useState(location.state.credentials);
  const [processandoToken, setProcessandoToken] = useState(false);

  const [filtro, setFiltro] = useState({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "",
      column: ""
    },
    operator: "In",
    values: ""
  });


  useEffect(() => {
    async function fetchData() {
      if (!processandoToken) {
        getEmbedToken();
      }
    }
    fetchData();
  }, [location.state.item.id]);



  async function getEmbedToken() {
    setProcessandoToken(true);

    const token = getLocalToken();
 
    const headers = {
      headers: {
        auth: token
      }
    };

    const body = {
      clientId: credentials.clientId,
      clientSecret: credentials.clientSecret,
      tenantId: credentials.tenantId,
      workspaceId: location.state.item.workspaceid,
      reportId: location.state.item.id_powerbi

    }

    //pegar filtro
    if (location.state.item.filter != null) {
      location.state.item.filter.map((item, i) => {
        setFiltro((old) => ({
          ...old,
          target: {
            table: item.table,
            column: item.column
          },
          values: item.values
        }))
      })
    }

    try {
      const response = await api.post('/getEmbedToken', body, headers)
        .then(res => res.data)
        .then(data => setEmbedConfig((old) => ({
          ...old,
          id: data.reportId,
          embedUrl: data.embedUrl,
          accessToken: data.accessToken,
          filter: [filtro]
        })));

      registerClickReport(location.state.usuario.id, location.state.item.id_powerbi);

    } catch (error) {
      setError(true);

    }




    setProcessandoToken(false);
  }



  if (!location.state || !location.state.item) {
    return <Redirect to="/home" />
  }

  const activateFullScreen = () => {
    embeddedComponent &&
      embeddedComponent.fullscreen &&
      embeddedComponent.fullscreen()
  }

  return (
    <>
      {error ? (
        <Row>
          <div className="card-div">
          <Card>
            <BlankState
              prefix="O dashboard selecionado"
              type="general" />
          </Card>
        </div>
        </Row>

      ) : (
          <div className="content-box">
            <div className="button-container">
              <Button
                className="u-mv-lg"
                type="primary"
                size="sm"
                text="Visualizar em FullScreen"
                onClick={activateFullScreen}
              />
            </div>
            <PowerBIEmbed
              embedConfig={embedConfig}
              cssClassName="powerbi-embedded-container"
              getEmbeddedComponent={setEmbeddedComponent}
            />
          </div>
        )}

    </>
  )
}
export default withRouter(DashboardViewerPage)
