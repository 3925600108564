import './Calendar.scss';
import {
  SelectInput,
} from '../../icts-form';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBr from 'date-fns/locale/pt-BR';
import Button from '../../icts-button';
import range from 'lodash/range';
import { getMonth, getYear } from 'date-fns';

registerLocale('pt-BR', ptBr);
setDefaultLocale('pt-BR', ptBr);

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const selectMonths = months.map((label, value) => ({
  label,
  value,
}));

const years = range(1940, getYear(new Date()) + 1, 1).map((year) => ({
  value: year,
  label: year,
})).reverse();

const Header = ({
  date,
  changeYear,
  changeMonth,
}) => {
  const year = getYear(date);
  const month = getMonth(date);

  return (
    <div className="calendar__header">
      <div className="calendar__header-selects">
        <SelectInput
          sm
          searchable
          scrollable
          onSelect={({ value }) => changeMonth(value)}
          items={selectMonths}
          value={{
            label: months[month],
            value: month,
          }}
        />

        <SelectInput
          sm
          items={years}
          searchable
          scrollable
          onSelect={({ value }) => changeYear(value)}
          value={{
            label: year,
            value: year,
          }}
        />
      </div>
      <h4 className="calendar__title">{months[month]} {year}</h4>
    </div>
  );
};

const Calendar = ({
  value,
  onSave,
  onCancel,
  className,
}) => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    setDate(value);
  }, [
    value,
  ]);

  return (
    <DatePicker
      inline
      selected={date}
      renderCustomHeader={Header}
      calendarClassName={classNames('calendar', className)}
      onChange={setDate}
    >
      <div className="calendar__footer">
        <Button
          size="sm"
          type="secondary"
          text="Cancelar"
          onClick={() => {
            setDate(null);
            onCancel();
          }}
        />

        <Button
          size="sm"
          type="primary"
          text="Salvar data"
          onClick={() => onSave(date)}
        />
      </div>
    </DatePicker>
  );
};

export default Calendar;
