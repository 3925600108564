import ReactGA from 'react-ga';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetCredentials, getUserReports } from '../../Requests/Requests'
import queryString from 'query-string';
import { Row, Col } from 'icts-layout';
import BlankState from 'icts-blank-state';
import Card from 'icts-card';
import styles from '../Pages/Home/Home.module.scss';

export const LinkDashboard = (props) => {
  const [dashboard, setDashboard] = useState(false);
  const [processandoDashboards, setProcessandoDashboards] = useState(false);

  //credentials
  const [credentials, setCredentials] = useState(false);
  const [processandoCredenciais, setProcessandoCredenciais] = useState(false);

  const [usuario, setUsuario] = useState(props.location.state.user);

  const [relatorios, setRelatorios] = useState(false);


  //pegar credenciais
  useEffect(() => {
    async function fetchData() {
      if (!credentials && !processandoCredenciais) {
        let resultado = await GetCredentials();

        if (!resultado.status) {
          setCredentials({
            clientId: resultado[0].clientid,
            clientSecret: resultado[0].clientsecret,
            tenantId: resultado[0].tenantid,
          });
        }
      }
    }
    fetchData();
  }, [credentials]);

  useEffect(() => {
    async function fetchData() {
      if (!dashboard && !processandoDashboards) {
        getClientReports();

      }
    } fetchData();
  }, [dashboard]);



  //get relatorios do cliente selecionado
  async function getClientReports() {
    setProcessandoDashboards(true);

    const queryStringValues = queryString.parse(props.location.search);
    let clientId = queryStringValues.id;
    
    const reports = await getUserReports(usuario.id, clientId);
    

    if (reports.sucess) {
      setDashboard({
        type: 'report',
        relatorios: reports.dados

      });
    }

    setProcessandoDashboards(false);
  }

  const dashboardClickTracker = title => {
    ReactGA.event({
      category:'relatórios home',
      action:'click',
      label:`Relatorio acessado: ${title}` 
    })
  } 
  

  return (
    <React.Fragment>
       <Row className={styles['dashboard-list']}>
        {dashboard && dashboard.relatorios.length > 0 ? (
          dashboard.relatorios.map((item, i) => (
            <Col key={i} xl="3" lg="4" md="6" sm="12" xs="12">
              <Link
                to={{
                  pathname: '/dashboard-viewer',
                  state: {
                    credentials,
                    item,
                    usuario
                  }

                }}
                className={styles['dashboard-list__item']}
                onClick={() => dashboardClickTracker(item.nome)}
              >
                {item.nome}
              </Link>
            </Col>
          ))
        ) : (
        <div className="card-div">
        
             <Card>
                <BlankState
                     prefix="O cliente selecionado"
                     type="general" />
              </Card>
        
        </div>
          )}
      </Row>
    </React.Fragment>

  )

};
