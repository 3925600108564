import React, { createContext, useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import queryString from 'query-string';
import { registerLastLogon } from '../Requests/Requests'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const redirectToLogin = () => {
    window.location.href = `${process.env.REACT_APP_PORTAL_URL}/login?to=${process.env.REACT_APP_BASE_URL}`
  }

  const logout = () => {
    window.location.href = `${process.env.REACT_APP_PORTAL_URL}/logout?to=${process.env.REACT_APP_BASE_URL}`
  }

  // Verifica se tem um token na querystring e salva no localstorage
  const location = useLocation()
  const queryParams = queryString.parse(location.search)

  if (queryParams && queryParams.auth) {
    localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_KEY, queryParams.auth);
    


  }

//setar horario do login
  useEffect(() => {
    async function fetchData() {

      if(!localStorage.getItem("Authenticated")){
        const token = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_KEY);
        const idProp64 = token.substring(token.indexOf("."), token.lastIndexOf("."));
        const idProp = JSON.parse(Buffer.from(idProp64, 'base64').toString());
        localStorage.setItem("Authenticated", true);
        registerLastLogon(idProp.jti);
      }

      
      

    }fetchData();
  }, [])


  return (
    <AuthContext.Provider value={{ user: user, setUser, redirectToLogin, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}