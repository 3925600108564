import { Footer as ICTSFooter, Item } from 'icts-footer'

import React from 'react'
import logo from 'icts-assets/src/ICTS-Protiviti-negativo.png'

export const Footer = () => (
  <ICTSFooter logo={logo}>
    <Item
      type="email"
      link="client.success@aliant.com.br"
      value="client.success@aliant.com.br"
    />

    <Item link="https://www.facebook.com/ICTSProtiviti/" icon="facebook" />

    <Item link="https://twitter.com/icts_protiviti" icon="twitter" />

    <Item
      link="https://www.linkedin.com/company/icts-global/"
      icon="linkedin"
    />
    <Item type="version" value={'Versão: 1.0.0'} />
  </ICTSFooter>
)
