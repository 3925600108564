import { NotFoundPage } from '../Views/Pages/NotFound'
import DashboardViewerPage  from '../Views/Pages/DashboardViewer';
import {HomeRelatorios} from '../Views/Pages/Home/index';
import {LinkDashboard} from '../Views/Components/LinkDashboard';


export { CustomRoute } from './CustomRoute'

/**
 * Rotas da aplicação
 * @type import('react-router-dom').RouteProps & { protected?: boolean }[]
 */
export const Routes = [
  {
    path: '/dashboard-viewer',
    component: DashboardViewerPage,
    protected: true,
  },
  { path: ['/', '/home'], component: HomeRelatorios, exact: true, protected: true },
  { path: '/client', component: LinkDashboard, exact: true, protected: true},
  { path: '/404', component: NotFoundPage, exact: true },
  { path: '*', component: NotFoundPage.Redirect },
]
