import './BlankState.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'icts-icon';
import { prefixFactory } from 'icts-helpers';

const factory = prefixFactory('blank-state--');

const types = factory([
  'general',
  'table',
]);

const BlankState = ({ prefix, type }) => {
  const classes = classNames(
    'blank-state',
    types[type],
  );

  return (
    <div className={classes}>
      <div className="blank-state__content">
        <Icon
          size="lg"
          name="vazio"
          className="blank-state__icon"
        />
        <h3 className="blank-state__label">
          {`${prefix} não possui nenhum dado para ser exibido`}
        </h3>
      </div>
    </div>
  );
};

BlankState.propTypes = {
  type: PropTypes.string,
  prefix: PropTypes.string.isRequired,
};

BlankState.defaultProps = {
  type: 'general',
};

export default BlankState;
