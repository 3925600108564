import { Redirect as RouterRedirect, useLocation } from 'react-router-dom'

import React from 'react'

const Redirect = () => {
  const location = useLocation()
  return (
    <RouterRedirect
      to={{ pathname: '/404', state: { from: location.pathname } }}
    />
  )
}

export const NotFoundPage = () => {
  const location = useLocation()
  return (
    <h1>
      Page not found {location.state.from ? '- ' + location.state.from : ''}
    </h1>
  )
}

NotFoundPage.Redirect = Redirect
