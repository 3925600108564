import { useEffect, useRef } from 'react';

//criar eventListener
export default function useEventListener(eventName, handler, element = window) {
    //cria uma referencia que armazena o handler
    const saveHandler = useRef();

    useEffect(() => {
        saveHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            //cria o evento
            const eventListener = event => saveHandler.current(event);

            //adiciona o evento
            element.addEventListener(eventName, eventListener);

            //remove evento
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element]
    );

}