import { Box, Item, Menu, User, UserData } from 'icts-menu'
import React, { useContext, useEffect, useState } from 'react'

import brand from 'icts-assets/src/icts-diligencia.png'
import company from 'icts-assets/src/icts-logo.svg'
import { AuthContext } from '../../Contexts/auth'
import styles from './Header.module.scss';
import { GetUsersData } from '../../Requests/Requests';
import api from '../../Requests/api';
import './Nav.css';

export const Header = () => {
  const { logout } = useContext(AuthContext);
  const [processandoUser, setProcessandoUser] = useState(false);
  const [usuario, setUsuario] = useState(false);


  useEffect(() => {
    async function fetchData() {
      if (!usuario && !processandoUser) {
        getUserData();
      }

    } fetchData();
  }, [usuario]);

  //get userName
  async function getUserData() {
    setProcessandoUser(true);
    let user = await GetUsersData();

    if (user.success) {
      setUsuario({
        id: user.id,
        name: user.name,

      });
    }

    setProcessandoUser(false);
  }

  function gravarHorarioLogout() {
    if (usuario) {
      const body = {
        userId: usuario.id
      }
      try {
        api.post('/lastlogout', body)
          .then(function (response) {
            localStorage.removeItem("Authenticated")
            logout()
          })
      } catch (error) {
        console.log(error)
      }
    }
    else {
      logout();
    }


  }


  return (
    <Menu brand={brand} linkBrand="/home" company={company}>
      <div className="box-user">
        <Box>
          <UserData
            perfil={`Olá ${usuario ? usuario.name : ''}`}
            login={''}
          />
          <User className={styles['menu__box--on_top']} icon="usuario">
            <Item link="#" onClick={gravarHorarioLogout} value="Sair" />

          </User>
        </Box>
      </div>
    </Menu>
  )
}